// @ts-nocheck
import React, { ReactEventHandler, useContext, useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { appContext } from '../context/appContext'
import {
  Container, 
  List,
  ListItem, 
  Typography,
  TextField,
  Button,
  Fab
} from '@mui/material'
import AddIcon  from '@mui/icons-material/Add'
import CachedIcon from '@mui/icons-material/Cached'
import CloseIcon from '@mui/icons-material/Close';
import { useParams } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { IListing } from '../types'
import { useSnackbar } from 'notistack';
import {array_move} from '../services/moveArrayElement'





const ProjectCreate: React.FC = () => {

  const navigate = useNavigate();

  if (performance.navigation.type == performance.navigation.TYPE_RELOAD) {
    window.location = '/'
  }

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const {createListing, uploadMedia, user_name} = useContext(appContext)
  const [listingItem, setListingItem] = useState<IListing>()
  

  const updatedProps = useRef({acf: {}})

  const acf = useRef({})

  const respondLink = useRef('')

  const gallerStore = useRef([])


  const backToListing = () => {
    navigate('/');
  }


  //----Podcast--------------------------------------//


  const [podcast, setPodcast] = useState([])
  const [podcastURLs, setPodcastURLs] = useState<Array<string>>([])

  useEffect(() => {
    if(podcast.length < 1) return 

    const newPodcastUrl = []
    newPodcastUrl.push(podcast[0].name)

    setPodcastURLs([...newPodcastUrl])
  }, [podcast])

  const onPodcastChange = async (e: React.ChangeEvent<HTMLInputElement> | null) => {
    const files: FileList | null = e!.target.files
    setPodcast([...e!.target.files])
    const res = await uploadMedia(files![0])

    acf.current = {...acf.current, 'podcast': res.data.id }

    updatedProps.current = {...updatedProps.current, 'acf': {...updatedProps.current.acf, ...acf.current}}
  }  



  //---- Video--------------------------------------//


  const [projectVideo, setProjectVideo] = useState([])
  const [projectVideoURLs, setProjectVideoURLs] = useState<Array<string>>([])

  useEffect(() => {
    if(projectVideo.length < 1) return 

    const newProjectVideoUrl = []
    newProjectVideoUrl.push(projectVideo[0].name)

    setProjectVideoURLs([...newProjectVideoUrl])
  }, [projectVideo])

  const onVideoChange = async (e: React.ChangeEvent<HTMLInputElement> | null) => {
    const files: FileList | null = e!.target.files
    setProjectVideo([...e!.target.files])
    const res = await uploadMedia(files![0])

    acf.current = {...acf.current, 'video': res.data.id }

    updatedProps.current = {...updatedProps.current, 'acf': {...updatedProps.current.acf, ...acf.current}}
  }  


  //----- Feature image-----------------------------//


  const [featureImage, setFeatureImage] = useState([])
  const [featureImageURLs, setFeatureImageURLs] = useState<Array<string>>([])

  useEffect(() => {
    if(featureImage.length < 1) return 
    // @ts-ignore
    const newfeatureImageUrl = []
    newfeatureImageUrl.push(URL.createObjectURL(featureImage[0]))
      // @ts-ignore
    setFeatureImageURLs([...newfeatureImageUrl])
  }, [featureImage])

  const onFeatreImageChange = async (e: React.ChangeEvent<HTMLInputElement> | null) => {
    const files: FileList | null = e!.target.files
    // @ts-ignore
    setFeatureImage([...e!.target.files])
    const res = await uploadMedia(files![0])

    // @ts-ignore
    updatedProps.current = {...updatedProps.current, 'featured_media': res.data.id}
}


//---- Main image----------------------------------//

  const [mainImage, setMainImage] = useState([])
  const [mainImageURLs, setMainImageURLs] = useState<Array<string>>([])

  useEffect(() => {
    if(mainImage.length < 1) return 
    // @ts-ignore
    const newMainImageUrl = []
    newMainImageUrl.push(URL.createObjectURL(mainImage[0]))
      // @ts-ignore
    setMainImageURLs([...newMainImageUrl])
  }, [mainImage])

  const onMainImageChange = async (e: React.ChangeEvent<HTMLInputElement> | null) => {
    const files: FileList | null = e!.target.files
    // @ts-ignore
    setMainImage([...e!.target.files])
    const res = await uploadMedia(files![0])

    acf.current = {...acf.current, 'main_image': res.data.id }

    updatedProps.current = {...updatedProps.current, 'acf': {...updatedProps.current.acf, ...acf.current}}
  }  


  //---- Banner image------------------------------//

  const [bannerImage, setBannerImage] = useState([])
  const [bannerImageURLs, setBannerImageURLs] = useState<Array<string>>([])

  useEffect(() => {
    if(bannerImage.length < 1) return 

    const newBannerImageUrl = []
    newBannerImageUrl.push(URL.createObjectURL(bannerImage[0]))

    setBannerImageURLs([...newBannerImageUrl])
  }, [bannerImage])

  const onBannerImageChange = async (e: React.ChangeEvent<HTMLInputElement> | null) => {
    const files: FileList | null = e!.target.files
    // @ts-ignore
    setBannerImage([...e!.target.files])
    const res = await uploadMedia(files![0])

    acf.current = {...acf.current, 'banner_images': res.data.id }

    updatedProps.current = {...updatedProps.current, 'acf': {...updatedProps.current.acf, ...acf.current}}
  }  

  //---- Poster image----------------------------//

  const [posterImage, setPosterImage] = useState([])
  const [posterImageURLs, setPosterImageURLs] = useState<Array<string>>([])

  useEffect(() => {
    if(posterImage.length < 1) return 

    const newPosterImageUrl = []
    newPosterImageUrl.push(URL.createObjectURL(posterImage[0]))

    setPosterImageURLs([...newPosterImageUrl])
  }, [posterImage])

  const onPosterImageChange = async (e: React.ChangeEvent<HTMLInputElement> | null) => {
    const files: FileList | null = e!.target.files
    setPosterImage([...e!.target.files])
    const res = await uploadMedia(files![0])

    acf.current = {...acf.current, 'poster': res.data.id }

    updatedProps.current = {...updatedProps.current, 'acf': {...updatedProps.current.acf, ...acf.current}}
  }  


  //---- Logo image-----------------------------//

  const [logoImage, setLogoImage] = useState([])
  const [logoImageURLs, setLogoImageURLs] = useState<Array<string>>([])

  useEffect(() => {
    if(logoImage.length < 1) return 

    const newLogoImageUrl = []
    newLogoImageUrl.push(URL.createObjectURL(logoImage[0]))

    setLogoImageURLs([...newLogoImageUrl])
  }, [logoImage])

  const onLogoImageChange = async (e: React.ChangeEvent<HTMLInputElement> | null) => {
    const files: FileList | null = e!.target.files
    setLogoImage([...e!.target.files])
    const res = await uploadMedia(files![0])

    acf.current = {...acf.current, 'project_logo': res.data.id }

    updatedProps.current = {...updatedProps.current, 'acf': {...updatedProps.current.acf, ...acf.current}}
  }  


  //---- Gallery-----------------------------//

  const [galleryImage, setGalleryImage] = useState([])
  const [galleryImageURLs, setGalleryImageURLs] = useState<Array<{}>>([])

  useEffect(() => {
    if(galleryImage.length < 1) return 

    const newGalleryImageUrl = {}


    newGalleryImageUrl.thumbnail = URL.createObjectURL(galleryImage[0])

    console.log('newGalleryImageUrl', newGalleryImageUrl)


    setGalleryImageURLs(
      (prevState) => {
        return ([...prevState, newGalleryImageUrl])
      }
    )

  }, [galleryImage])


  const onGalleryImageChange = async (e: React.ChangeEvent<HTMLInputElement> | null) => {
    const files: FileList | null = e!.target.files
    setGalleryImage([...e!.target.files])


    const res = await uploadMedia(files![0])

    console.log('gallerStore before aditing image', gallerStore.current)
    gallerStore.current.push(res.data.id)


    acf.current = {...acf.current, 'gallery': [...gallerStore.current]}

    updatedProps.current = {...updatedProps.current, 'acf': {...updatedProps.current.acf, ...acf.current}}

    console.log('updatedProps.current updated after aditing image', updatedProps.current)
  }   
  
  const removeGalleryItemHandler = (id) => {

    setGalleryImageURLs(
      (prevState) => {
        let updatedState = prevState.slice()
        updatedState.splice(id, 1)
        return updatedState
      }
    )

    gallerStore.current.splice(id, 1)

    // const galleryStore: Array<string> = galleryImageURLs.map((galleryImageURL, id) => galleryImageURL.id).filter((ids, ID) => id != ID)
    acf.current = {...acf.current, 'gallery': [...gallerStore.current] }
    console.log('acf.current inside removeHadler', acf.current)
    updatedProps.current = {...updatedProps.current, 'acf': {...updatedProps.current.acf, ...acf.current}}  

  }

  useEffect(() => {
    console.log('useEffectStart')
    const imageItems = document.querySelectorAll('.images-wrapper .image-wrap .gallery-image')
    const boxes = document.querySelectorAll('.images-wrapper .image-wrap');
    let counter = 0
    let target = null
    console.log('imageItems', imageItems)
    console.log('boxes', boxes)

    const dragStart = (e) => {
      e.dataTransfer.setData('text/plain', e.target.id);
      console.log('drag start')
      setTimeout(() => {
          e.target.classList.add('hide');
      }, 0);
    }

    function dragEnter(e) {
      console.log('grag enter')
      if((e.target.classList.contains('image-wrap')) && (counter === 0) && (!e.target.contains(e.fromElement))) {
        // e.target.classList.add('drag-over')
        target = e.target
        e.stopPropagation();
        e.preventDefault();
        counter++
      }
    }   

    const dragOver = (e) => {
      e.preventDefault();
      e.stopPropagation();
    }
    
    function dragLeave (e) {
      if((e.target == target) && (counter === 1)) {
        e.stopPropagation();
        e.preventDefault();
        // target.classList.remove('drag-over')
        counter--
      }
    }  
 
    const drop = (e) => {
      const id = e.dataTransfer.getData('text/plain');
      const draggable = document.getElementById(id);
      draggable.classList.remove('hide')
      if(!target) {
        return 
      }

      console.log('target', target.id, 'image id', id)

      // target.classList.remove('drag-over');

      draggable.classList.remove('hide')
      console.log('target', target.id, 'image id', id)
      const draggableIndex = id.substring(6)
      const targetIndex = Number(target.id)

      setGalleryImageURLs(
        (prevState) => {
          let updatedState = prevState.slice()
          console.log('draggableIndex', draggableIndex)
          // const draggable = updatedState[draggableIndex]
          console.log('targetIndex', targetIndex, 'draggableIndex', draggableIndex)
          // updatedState.splice(targetIndex, 0, draggable)
          array_move(updatedState, draggableIndex, targetIndex)
          return updatedState
        }
      )

      array_move(gallerStore.current, draggableIndex, targetIndex)

      acf.current = {...acf.current, 'gallery': [...gallerStore.current]}

      updatedProps.current = {...updatedProps.current, 'acf': {...updatedProps.current.acf, ...acf.current}}

      target = null
      counter = 0
    }

    imageItems.forEach((item) => {
      item.addEventListener('dragstart', dragStart);
    })

    boxes.forEach((box) => {
      box.addEventListener('dragenter', dragEnter)
      box.addEventListener('dragover', dragOver);
      box.addEventListener('dragleave', dragLeave);
      box.addEventListener('drop', drop);
    })

    return () => {

      boxes.forEach((box) => {
        box.removeEventListener('dragenter', dragEnter)
        box.removeEventListener('dragover', dragOver);
        box.removeEventListener('dragleave', dragLeave);
        box.removeEventListener('drop', drop);
      })

      imageItems.forEach((item) => {
        item.removeEventListener('dragstart', dragStart);
      })

    }

  }, [galleryImageURLs])

  //-------------------------------------------------------------//
  //-------------------------------------------------------------//
  //-------------------------------------------------------------//
  

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IListing>();

  const submitHandler = async (formData: IListing ) => {
   console.log('formData', formData)
   console.log('updatedProps', updatedProps) 

   let fields = {}
   let acf = {}

   for (const key in formData) {
     if(key.includes('acf_')) {
       acf = {...acf, [key.slice(4)]: formData[key]}
     } else {
       fields = {...fields, [key]: formData[key]}
     }

  }

  updatedProps.current = {...updatedProps.current, ...fields}

  updatedProps.current = {...updatedProps.current, 'acf': {...updatedProps.current.acf, ...acf}, 'status': 'publish'}

  console.log('fields', fields)
  console.log('acf', acf)


  console.log('updatedProps2', updatedProps.current) 



  const respond = await createListing(updatedProps.current)
  console.log('publish', respond, respond.status)

  if(respond && respond.status == 201) {
    enqueueSnackbar('The project has been successefully created', { variant: 'success'})
    // respondLink.current = respond.data.link
    // document.querySelector('.respond-wrapper')?.classList.add('show')
  }


}

  return (
    <>
    <div className="project-heading">
      <Container maxWidth="lg">
        <Button
          color="secondary"
          size="small"
          className='cta-small cta-back'
          onClick={backToListing}
        >
          Back to Projects
        </Button>
      </Container>
    </div>  

    <Container maxWidth="md">
      <form onSubmit={handleSubmit(submitHandler)} className="login-form">

          <Typography component="h1" variant="h1">
            New Project
          </Typography>

        <List>

{/*--------------Feature image----------------------------------*/}

        <ListItem sx={{p: 0, mb: 3}}>
            <label style={{width: '100px', display: 'block'}} htmlFor="">Featured image</label>
            <div className='images-wrapper'>
            {featureImageURLs.map((imageSrc, id) =>
              <img key={id} src={imageSrc} alt="Feature"/>  
            )}
            </div>      

            <label htmlFor="feature_image">
              <input
                style={{ display: 'none' }}
                id="feature_image"
                name="feature_image"
                accept='image/*'
                type="file"
                onChange={onFeatreImageChange}
              />

            <Fab color="primary" size="small" component="span" aria-label="add">
                <AddIcon />
            </Fab>
            </label>
          </ListItem> 

{/*--------------Main image----------------------------------*/}          

        <ListItem sx={{p: 0, mb: 3}}>
            <label style={{width: '100px', display: 'block'}} htmlFor="">Main image</label>
            <div className='images-wrapper'>
            {mainImageURLs.map((imageSrc, id) => <img key={id} src={imageSrc} alt="Main"/>  )}
            </div>      

            <label htmlFor="main_image">
              <input
                style={{ display: 'none' }}
                id="main_image"
                name="main_image"
                accept='image/*'
                type="file"
                onChange={onMainImageChange}
              />

            <Fab color="primary" size="small" component="span" aria-label="add">
                <AddIcon />
            </Fab>
            </label>
        </ListItem> 

{/*--------------Logo image----------------------------------*/}                       

        <ListItem sx={{p: 0, mb: 3}}>
            <label style={{width: '100px', display: 'block'}} htmlFor="">Project logo</label>
            <div className='images-wrapper'>
            {logoImageURLs.map((imageSrc, id) => <img key={id} src={imageSrc} alt="Main"/>  )}
            </div>      

            <label htmlFor="logo_image">
              <input
                style={{ display: 'none' }}
                id="logo_image"
                name="logo_image"
                accept='image/*'
                type="file"
                onChange={onLogoImageChange}
              />

            <Fab color="primary" size="small" component="span" aria-label="add">
                <AddIcon />
            </Fab>
            </label>
        </ListItem>               

{/*--------------Title----------------------------------------*/}

          <ListItem sx={{p: 0, mb: 3}}>
            <Controller
              name="title"
              control={control}
              defaultValue={''}
              rules={{
                required: true,
              }}
              render={({field}) => (
                <TextField
                  variant="outlined"
                  fullWidth
                  id="title"
                  label="Street Address"
                  error={Boolean(errors.title)}
                  sx={{input: {backgroundColor: 'white'}}}
                  InputLabelProps={{
                    style: { fontSize: '20px', color: 'black', paddingLeft: '6px'},
                  }}
                  helperText={errors.title 
                    ? 'Title is required'
                    : ''}
                  {...field}    
                ></TextField>
              )}
            ></Controller>
          </ListItem>

{/*--------------Suburb--------------------------------------*/} 

          <ListItem sx={{p: 0, mb: 3}}>
            <Controller
              name="acf_suburb"
              control={control}
              // rules={{
              //   required: true,
              // }}
              render={({field}) => (
                <TextField
                  variant="outlined"
                  fullWidth
                  id="acf_suburb"
                  label="Suburb"
                  error={Boolean(errors.acf_suburb)}
                  sx={{input: {backgroundColor: 'white'}}}
                  InputLabelProps={{
                    style: { fontSize: '20px', color: 'black', paddingLeft: '6px'},
                  }}
                  // helperText={errors.title 
                  //   ? 'Title is required'
                  //   : ''}
                    {...field}   
                ></TextField>
              )}
            ></Controller>
          </ListItem>            

{/*--------------City--------------------------------------*/} 

          <ListItem sx={{p: 0, mb: 3}}>
            <Controller
              name="acf_city"
              control={control}
              // rules={{
              //   required: true,
              // }}
              render={({field}) => (
                <TextField
                  variant="outlined"
                  fullWidth
                  id="acf_city"
                  label="City"
                  error={Boolean(errors.acf_city)}
                  sx={{input: {backgroundColor: 'white'}}}
                  InputLabelProps={{
                    style: { fontSize: '20px', color: 'black', paddingLeft: '6px'},
                  }}
                  // helperText={errors.title 
                  //   ? 'Title is required'
                  //   : ''}
                    {...field}   
                ></TextField>
              )}
            ></Controller>
          </ListItem>       

{/*--------------Post Code-----------------------------------*/} 

          <ListItem sx={{p: 0, mb: 3}}>
            <Controller
              name="acf_post_code"
              control={control}
              // rules={{
              //   required: true,
              // }}
              render={({field}) => (
                <TextField
                  variant="outlined"
                  fullWidth
                  id="acf_post_code"
                  label="Post Code"
                  error={Boolean(errors.acf_post_code)}
                  sx={{input: {backgroundColor: 'white'}}}
                  InputLabelProps={{
                    style: { fontSize: '20px', color: 'black', paddingLeft: '6px'},
                  }}
                  // helperText={errors.title 
                  //   ? 'Title is required'
                  //   : ''}
                    {...field}   
                ></TextField>
              )}
            ></Controller>
          </ListItem>     

{/*--------------Price--------------------------------------*/} 

          <ListItem sx={{p: 0, mb: 2}}>
            <Controller
              name="acf_price"
              control={control}
              // rules={{
              //   required: true,
              // }}
              render={({field}) => (
                <TextField
                  variant="outlined"
                  fullWidth
                  id="acf_price"
                  label="Price"
                  error={Boolean(errors.acf_price)}
                  sx={{input: {backgroundColor: 'white'}}}
                  InputLabelProps={{
                    style: { fontSize: '20px', color: 'black', paddingLeft: '6px'},
                  }}
                  // helperText={errors.title 
                  //   ? 'Title is required'
                  //   : ''}
                    {...field}   
                ></TextField>
              )}
            ></Controller>
          </ListItem>  

{/*--------------Developer--------------------------------------*/} 

          <ListItem sx={{p: 0, mb: 2}}>
            <Controller
              name="acf_developer"
              control={control}
              // rules={{
              //   required: true,
              // }}
              render={({field}) => (
                <TextField
                  variant="outlined"
                  fullWidth
                  id="acf_developer"
                  label="Developer"
                  error={Boolean(errors.acf_developer)}
                  sx={{input: {backgroundColor: 'white'}}}
                  InputLabelProps={{
                    style: { fontSize: '20px', color: 'black', paddingLeft: '6px'},
                  }}
                  // helperText={errors.title 
                  //   ? 'Title is required'
                  //   : ''}
                    {...field}   
                ></TextField>
              )}
            ></Controller>
          </ListItem>         

{/*--------------Architect--------------------------------------*/} 

          <ListItem sx={{p: 0, mb: 3}}>
            <Controller
              name="acf_architect"
              control={control}
              // rules={{
              //   required: true,
              // }}
              render={({field}) => (
                <TextField
                  variant="outlined"
                  fullWidth
                  id="acf_architect"
                  label="Architect"
                  error={Boolean(errors.acf_architect)}
                  sx={{input: {backgroundColor: 'white'}}}
                  InputLabelProps={{
                    style: { fontSize: '20px', color: 'black', paddingLeft: '6px'},
                  }}
                  // helperText={errors.title 
                  //   ? 'Title is required'
                  //   : ''}
                    {...field}   
                ></TextField>
              )}
            ></Controller>
          </ListItem>    

{/*--------------Expected Completion-------------------------------*/} 

          <ListItem sx={{p: 0, mb: 3}}>
            <Controller
              name="acf_expected_completion"
              control={control}
              // rules={{
              //   required: true,
              // }}
              render={({field}) => (
                <TextField
                  variant="outlined"
                  fullWidth
                  id="acf_expected_completion"
                  label="Expected Completion Date"
                  error={Boolean(errors.acf_expected_completion)}
                  sx={{input: {backgroundColor: 'white'}}}
                  InputLabelProps={{
                    style: { fontSize: '20px', color: 'black', paddingLeft: '6px'},
                  }}
                  // helperText={errors.title 
                  //   ? 'Title is required'
                  //   : ''}
                    {...field}   
                ></TextField>
              )}
            ></Controller>
          </ListItem>                                

{/*--------------Bedroom--------------------------------------*/} 

          <ListItem sx={{p: 0, mb: 3}}>
            <Controller
              name="acf_bedroom"
              control={control}
              rules={{
                required: true,
                pattern: /^[0-9]?\s?-?\s?[0-9]$/i,
              }}
              render={({field}) => (
                <TextField
                  variant="outlined"
                  fullWidth
                  id="acf_bedroom"
                  label="Bedroom"
                  error={Boolean(errors.acf_bedroom)}
                  sx={{input: {backgroundColor: 'white'}}}
                  InputLabelProps={{
                    style: { fontSize: '20px', color: 'black', paddingLeft: '6px'},
                  }}
                  helperText={
                    errors.acf_bedroom
                      ? errors.acf_bedroom.type === 'pattern'
                        ? 'Only numbers and symbole "-" are allowed'
                        : ''
                      : ''
                  }
                    {...field}   
                ></TextField>
              )}
            ></Controller>
          </ListItem>           

{/*--------------Bathroom--------------------------------------*/} 

          <ListItem sx={{p: 0, mb: 3}}>
            <Controller
              name="acf_bathroom"
              control={control}
              rules={{
                required: true,
                pattern: /^[0-9]?\s?-?\s?[0-9]$/i,
              }}
              render={({field}) => (
                <TextField
                  variant="outlined"
                  fullWidth
                  id="acf_bathroom"
                  label="Bathroom"
                  error={Boolean(errors.acf_bathroom)}
                  sx={{input: {backgroundColor: 'white'}}}
                  InputLabelProps={{
                    style: { fontSize: '20px', color: 'black', paddingLeft: '6px'},
                  }}
                  helperText={
                    errors.acf_bathroom
                      ? errors.acf_bathroom.type === 'pattern'
                        ? 'Only numbers and symbole "-" are allowed'
                        : ''
                      : ''
                  }
                    {...field}   
                ></TextField>
              )}
            ></Controller>
          </ListItem>          

{/*--------------Car--------------------------------------*/} 

          <ListItem sx={{p: 0, mb: 3}}>
            <Controller
              name="acf_car"
              control={control}
              rules={{
                required: true,
                pattern: /^[0-9]?\s?-?\s?[0-9]$/i,
              }}
              render={({field}) => (
                <TextField
                  variant="outlined"
                  fullWidth
                  id="acf_car"
                  label="Car"
                  error={Boolean(errors.acf_car)}
                  sx={{input: {backgroundColor: 'white'}}}
                  InputLabelProps={{
                    style: { fontSize: '20px', color: 'black', paddingLeft: '6px'},
                  }}
                  helperText={
                    errors.acf_car
                      ? errors.acf_car.type === 'pattern'
                        ? 'Only numbers and symbole "-" are allowed'
                        : ''
                      : ''
                  }
                    {...field}   
                ></TextField>
              )}
            ></Controller>
          </ListItem>    

{/*--------------Banner image----------------------------------*/}             

          <ListItem sx={{p: 0, mb: 3}}>
            <label style={{width: '100px', display: 'block'}} htmlFor="">Banner Image</label>
            <div className='images-wrapper'>
            {bannerImageURLs.map((imageSrc, id) => <img key={id} src={imageSrc} alt="Main"/>  )}
            </div>      

            <label htmlFor="banner_image">
              <input
                style={{ display: 'none' }}
                id="banner_image"
                name="banner_image"
                accept='image/*'
                type="file"
                onChange={onBannerImageChange}
              />

            <Fab color="primary" size="small" component="span" aria-label="add">
                <AddIcon />
            </Fab>
            </label>
          </ListItem>    

{/*--------------Headline--------------------------------------*/} 

          <ListItem sx={{p: 0, mb: 3}}>
            <Controller
              name="acf_headline"
              control={control}
              // rules={{
              //   required: true,
              // }}
              render={({field}) => (
                <TextField
                  variant="outlined"
                  fullWidth
                  id="acf_headline"
                  label="Headline"
                  error={Boolean(errors.acf_headline)}
                  sx={{input: {backgroundColor: 'white'}}}
                  InputLabelProps={{
                    style: { fontSize: '20px', color: 'black', paddingLeft: '6px'},
                  }}
                  // helperText={errors.title 
                  //   ? 'Title is required'
                  //   : ''}
                    {...field}   
                ></TextField>
              )}
            ></Controller>
          </ListItem> 

{/*--------------Content----------------------------------------*/}

          <ListItem sx={{p: 0, mb: 3}}>
            <Controller
              name="content"
              control={control}
              rules={{
                required: true,
              }}
              render={({field}) => (
                <TextField
                  variant="outlined"
                  fullWidth
                  id="conten"
                  label="Content"
                  multiline
                  rows={10}
                  sx={{textarea: {backgroundColor: 'white', p: 1}}}
                  InputLabelProps={{
                    style: { fontSize: '20px', color: 'black', paddingLeft: '6px'},
                  }}
                  error={Boolean(errors.content)}
                  helperText={errors.content 
                    ? 'Content is required'
                    : ''}
                    {...field}   
                ></TextField>
              )}
            ></Controller>
          </ListItem>          

{/*--------------Key Features--------------------------------------*/} 

          <ListItem sx={{p: 0, mb: 3}}>
            <Controller
              name="acf_key_features"
              control={control}
              // rules={{
              //   required: true,
              // }}
              render={({field}) => (
                <TextField
                  variant="outlined"
                  fullWidth
                  id="acf_key_features"
                  label="Key Features"
                  multiline
                  rows={8} 
                  sx={{textarea: {backgroundColor: 'white', p: 1}}}                 
                  error={Boolean(errors.acf_key_features)}
                  InputLabelProps={{
                    style: { fontSize: '20px', color: 'black', paddingLeft: '6px'},
                  }}
                  // helperText={errors.title 
                  //   ? 'Title is required'
                  //   : ''}
                    {...field}   
                ></TextField>
              )}
            ></Controller>
          </ListItem>                     

{/*--------------Video Poster----------------------------------*/}             

{ user_name === 'admin' 
        ?
          <ListItem sx={{p: 0, mb: 3}}>
            <label style={{width: '100px', display: 'block'}} htmlFor="">Video Poster</label>
            <div className='images-wrapper'>
            {posterImageURLs.map((imageSrc, id) => <img key={id} src={imageSrc} alt="Main"/>  )}
            </div>      

            <label htmlFor="poster_image">
              <input
                style={{ display: 'none' }}
                id="poster_image"
                name="poster_image"
                accept='image/*'
                type="file"
                onChange={onPosterImageChange}
              />

            <Fab color="primary" size="small" component="span" aria-label="add">
                <AddIcon />
            </Fab>
            </label>
          </ListItem>  
        : ''
}          
      
{/*--------------Project video----------------------------------*/}           

{ user_name === 'admin' 
        ?
          <ListItem sx={{p: 0, mb: 3}}>
            <label style={{width: '100px', display: 'block'}} htmlFor="">Project video</label>
            <div className='images-wrapper'>
            {projectVideoURLs.map((imageSrc, id) => <p key={id} className="form-text">{imageSrc.substring(imageSrc.lastIndexOf('/')+1)}</p>   )}
            </div>      

            <label htmlFor="project_video">
              <input
                style={{ display: 'none' }}
                id="project_video"
                name="project_video"
                accept='.mp4'
                type="file"
                onChange={onVideoChange}
              />

            <Fab color="primary" size="small" component="span" aria-label="add">
                <AddIcon />
            </Fab>
            </label>
          </ListItem>  
        : ''
}          

{/*--------------Podcast Title-------------------------------------------*/} 

{ user_name === 'admin' 
        ?
          <ListItem sx={{p: 0, mb: 3}}>
            <Controller
              name="acf_podcast_title"
              control={control}
              // rules={{
              //   required: true,
              // }}
              render={({field}) => (
                <TextField
                  variant="outlined"
                  fullWidth
                  id="acf_podcast_title"
                  label="Podcast Title"
                  error={Boolean(errors.acf_podcast_title)}
                  sx={{input: {backgroundColor: 'white'}}}
                  InputLabelProps={{
                    style: { fontSize: '20px', color: 'black', paddingLeft: '6px'},
                  }}
                  // helperText={errors.title 
                  //   ? 'Title is required'
                  //   : ''}
                    {...field}   
                ></TextField>
              )}
            ></Controller>
          </ListItem>  
          : ''
}                              

{/*--------------Project podcast----------------------------------*/}           

{ user_name === 'admin' 
        ?
          <ListItem sx={{p: 0, mb: 3}}>
            <label style={{width: '100px', display: 'block'}} htmlFor="">Podcast</label>
            <div className='images-wrapper'>
            {podcastURLs.map((imageSrc, id) => <p key={id} className="form-text">{imageSrc.substring(imageSrc.lastIndexOf('/')+1)}</p>   )}
            </div>      

            <label htmlFor="podcast">
              <input
                style={{ display: 'none' }}
                id="podcast"
                name="podcast"
                accept='.mp3'
                type="file"
                onChange={onPodcastChange}
              />

            <Fab color="primary" size="small" component="span" aria-label="add">
                <AddIcon />
            </Fab>
            </label>
          </ListItem>   
          : ''
}                         

{/*--------------Gallery------------------------------------*/}                       

          <ListItem sx={{p: 0, mb: 3}} className="gallery-input">
            <label style={{width: '100px', display: 'block'}} htmlFor="">Gallery images</label>
            <div className='images-wrapper'>
            {galleryImageURLs.map((imageSrc, id) => {
            return (
              <div className='image-wrap' key={id} id={id}>
                <img src={imageSrc.thumbnail} className="gallery-image" id={`image_${id}`} alt="Gallery"/>
                <Fab onClick={() => removeGalleryItemHandler(id)} color="primary" size="small" component="span" aria-label="add" sx={{width: '24px', height: '24px', minHeight: '24px'}}>
                  <CloseIcon />
                </Fab>
              </div>
            ) } )}
            </div>      

            <label htmlFor="gallery_image">
              <input
                style={{ display: 'none' }}
                id="gallery_image"
                name="gallery_image"
                accept='image/*'
                type="file"
                onChange={onGalleryImageChange}
              />

            <Fab color="primary" size="small" component="span" aria-label="add">
                <AddIcon />
            </Fab>
            </label>
          </ListItem>              

{/*--------------Agent Name--------------------------------------*/} 

          <ListItem sx={{p: 0, mb: 3}}>
            <Controller
              name="acf_agent_name"
              control={control}
              // rules={{
              //   required: true,
              // }}
              render={({field}) => (
                <TextField
                  variant="outlined"
                  fullWidth
                  id="acf_agent_name"
                  label="Agent Name"
                  error={Boolean(errors.acf_agent_name)}
                  sx={{input: {backgroundColor: 'white'}}}
                  InputLabelProps={{
                    style: { fontSize: '20px', color: 'black', paddingLeft: '6px'},
                  }}
                  // helperText={errors.title 
                  //   ? 'Title is required'
                  //   : ''}
                    {...field}   
                ></TextField>
              )}
            ></Controller>
          </ListItem> 

{/*--------------Phone-------------------------------------------*/} 

          <ListItem sx={{p: 0, mb: 3}}>
            <Controller
              name="acf_phone"
              control={control}
              // rules={{
              //   required: true,
              // }}
              render={({field}) => (
                <TextField
                  variant="outlined"
                  fullWidth
                  id="acf_phone"
                  label="Phone"
                  error={Boolean(errors.acf_phone)}
                  sx={{input: {backgroundColor: 'white'}}}
                  InputLabelProps={{
                    style: { fontSize: '20px', color: 'black', paddingLeft: '6px'},
                  }}
                  // helperText={errors.title 
                  //   ? 'Title is required'
                  //   : ''}
                    {...field}   
                ></TextField>
              )}
            ></Controller>
          </ListItem>           

{/*--------------Email-------------------------------------------*/} 

          <ListItem sx={{p: 0, mb: 3}}>
            <Controller
              name="acf_email"
              control={control}
              // rules={{
              //   required: true,
              // }}
              render={({field}) => (
                <TextField
                  variant="outlined"
                  fullWidth
                  id="acf_email"
                  label="Email"
                  error={Boolean(errors.acf_email)}
                  sx={{input: {backgroundColor: 'white'}}}
                  InputLabelProps={{
                    style: { fontSize: '20px', color: 'black', paddingLeft: '6px'},
                  }}
                  // helperText={errors.title 
                  //   ? 'Title is required'
                  //   : ''}
                    {...field}   
                ></TextField>
              )}
            ></Controller>
          </ListItem>           

{/*--------------Website-------------------------------------------*/} 

          <ListItem sx={{p: 0, mb: 3}}>
            <Controller
              name="acf_website"
              control={control}
              // rules={{
              //   required: true,
              // }}
              render={({field}) => (
                <TextField
                  variant="outlined"
                  fullWidth
                  id="acf_website"
                  label="Website"
                  error={Boolean(errors.acf_website)}
                  sx={{input: {backgroundColor: 'white'}}}
                  InputLabelProps={{
                    style: { fontSize: '20px', color: 'black', paddingLeft: '6px'},
                  }}
                  // helperText={errors.title 
                  //   ? 'Title is required'
                  //   : ''}
                    {...field}   
                ></TextField>
              )}
            ></Controller>
          </ListItem>     
    
{/*--------------------------------------------------------*/}           

          <ListItem sx={{p: 0, mb: 2, mt: 5}}>
            <Button variant="contained" type="submit" fullWidth className='cta'>
              Submit
            </Button>
          </ListItem>

          <ListItem className='respond-wrapper'>
              Check the project following the link 
              <a target="_blank" rel="noreferrer" href={respondLink.current}>&nbsp;here</a>
          </ListItem>

        </List>

      </form>
    </Container>  
    </>  
  )
}

export default ProjectCreate