import React, {useContext} from 'react'
import {appContext} from '../context/appContext'
// import logo from '/images/bolqld-logo-small.svg'
import {AppBar, Toolbar, Container, Link, IconButton, Typography} from '@mui/material'
// import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon  from '@mui/icons-material/Logout';





export const Navbar: React.FC = () => {

  // @ts-ignore
  const {logout} = useContext(appContext)




  return (
    <AppBar position="relative" >
      <Container maxWidth="lg">
      <Toolbar>
        <Link href="/">
          <img className="top-logo" src="/images/bolqld-logo-small.svg" alt="Logo" />
        </Link>
        <div style={{flexGrow: "1", marginLeft: "10rem"}}>
          <a target="_blank"  rel="noreferrer noopener" href="https://bestofliving.com.au/">
            <Typography variant="h5">Best of Living Website</Typography>
          </a>
        </div>
        {(true)? (        
        <IconButton onClick={logout}>
          <LogoutIcon color={"secondary"} fontSize={"large"}/>
        </IconButton>
        ) :
        ''}
      </Toolbar>
      </Container>
      </AppBar>      
  )
}
