import React, {useContext} from 'react'
import {Typography} from '@mui/material'
import Login from '../components/Login'
import Listing from '../components/Listing'
import {appContext} from '../context/appContext'






const Home: React.FC = () => {
  // @ts-ignore
  const {token} = useContext(appContext)

  return (
    <>{
      !token ?
      <Login/> :
      <Listing/>
      }
    </>
  )
}

export default Home

