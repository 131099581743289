import React from 'react'
import {Container} from '@mui/material'
import { Footer } from './Footer'
import { Navbar } from './Navbar'
import {Props} from '../types'

export const Layout: React.FC<Props> = ({children}) => {
  return (
    <div className="layout">
      <Navbar/>
      <main className="main">
        <Container maxWidth="lg">
          {children}
        </Container>
      </main>
      <Footer/>
    </div>
  )
}

