import { Reducer } from 'react';
import {
  USER_LOGIN,
  USER_LOGOUT,
  FETCH_REQUEST,
  REMOVE_LISTING
} from './appTypes'
import {IAppContext, actionType, IAction, IListing} from '../types'




const handlers = {
  [USER_LOGIN]: (state: IAppContext, {payload}: IAction ) => ({ ...state, token: payload.token, user_id: payload.user_id, user_name: payload.user_name }),
  [USER_LOGOUT]: (state: IAppContext) => ({ ...state, token: "", listing: [] }),
  [FETCH_REQUEST]: (state: IAppContext, { payload}: IAction) => ({ ...state, listing: payload }),
  [REMOVE_LISTING]: (state: IAppContext, {payload}: IAction ) => ({ ...state, listing: payload }),
  'DEFAULT': (state: IAppContext) => state,
}

export const appReducer: Reducer<IAppContext, IAction> = (state, action) => {
  const handler = handlers[action.type] || handlers.DEFAULT;
  return handler(state, action);
};


