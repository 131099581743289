import React, { useReducer } from "react";
import axios from "axios";
import Cookies from 'js-cookie';
import { useSnackbar } from 'notistack';
import {  
  USER_LOGIN,
  USER_LOGOUT,
  FETCH_REQUEST,
  REMOVE_LISTING
} from './appTypes'

import {IAppContext, Props, FormValues} from '../types'
import {appReducer} from '../context/appReducer'
import {appContext} from '../context/appContext'
import {restToProps} from '../services/restToProperties'

const siteUrl = 'https://bol.thedigitaltree.com.au'

export const AppState: React.FC<Props> = ({ children}) => {

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const initialState: IAppContext = {
    token: Cookies.get('token')
    ? JSON.parse(Cookies.get('token')!)
    : null,
    user_id: Cookies.get('user_id')
    ? JSON.parse(Cookies.get('user_id')!)
    : null,
    user_name: Cookies.get('user_name')
    ? JSON.parse(Cookies.get('user_name')!)
    : null,
    listing: [],
    loading: false,
  }

  const [state, dispatch] = useReducer(appReducer, initialState);

  const {
    token,
    user_id,
    user_name,
    listing,
    loading,
  } = state;

  const getToken = async (formData: FormValues) => {
    try {
      const res = await axios.post(
        `${siteUrl}/wp-json/jwt-auth/v1/token`,
        formData
      );
      const token: string  = res.data.token 
      const user_id: string  = res.data.user_id.toString() 
      const user_name: string  = res.data.user_nicename

      dispatch({
        type: USER_LOGIN,
        payload: {token, user_id, user_name}
      })
      Cookies.set('token', JSON.stringify(token))
      Cookies.set('user_id', JSON.stringify(user_id))
      Cookies.set('user_name', JSON.stringify(user_name))


    } catch (err) {
      console.log("caught error", err);
    }   
  }

  const logout = () => {
    // @ts-ignore
    window.location = '/'
    dispatch({
      type: USER_LOGOUT,
    })
    Cookies.remove('token')
    Cookies.remove('user_id')
    Cookies.remove('user_name')

  }

  const getListing = async (user_id: string) => {
    let author = ''
    if(!user_id) {
      author = `&author=${user_id}`
    } 

    try {
      const res = await axios.get(
        `${siteUrl}/wp-json/wp/v2/properties?acf_format=standard${author}`
      );

      const data = restToProps(res.data) 

      console.log('original data', res.data)
      console.log('after parsing', data)

      dispatch({
        type: FETCH_REQUEST,
        payload: data
      })

    } catch(err) {
      console.log("caught error", err);
    } 
  }

  // const getListingItem = async (id: string) => {

  //   try {
  //     const res = await axios.get(
  //       `${siteUrl}/wp-json/wp/v2/properties/${id}?acf_format=standard`
  //     );

  //     const data = restToProps(res.data) 

  //     return data

  //   } catch(err) {
  //     console.log("caught error", err);
  //   } 
  // }

  const createListing = async (data: any) => {
    try {
      return await axios.post(
       `${siteUrl}/wp-json/wp/v2/properties/`,
       data,
       {
         headers: {
         Authorization: `Bearer ${token}`,
         }
       }
     );

   } catch (err) {
     console.log("caught error", err);
   }  
  }




  const removeListing = async (id: string) => {
    const updatedListing = listing.filter((listingItem) => {
      return listingItem.id !== id
    })

    try {
      const res = await axios.delete(
        `${siteUrl}/wp-json/wp/v2/properties/${id}`,
        {
          headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          }
        }
      );

      if (res.data.id == id) {
        dispatch({
          type: REMOVE_LISTING,
          payload: updatedListing
        })
        
        enqueueSnackbar('The project has been successefully removed', { variant: 'success'})
      }

    } catch(err) {
      console.log("caught error", err);
    }  


  }


  const uploadMedia = async (file: any) => {
    const ext = file.name.substr(file.name.lastIndexOf('.') + 1)
    try {
       return await axios.post(
        `${siteUrl}/wp-json/wp/v2/media`,
        file,
        {
          headers: {
          Authorization: `Bearer ${token}`,
          'Content-Disposition':`attachment; filename="${file.name}"`,
          'Content-Type':`image/${ext}`
          // 'Cache-Control': 'no-cache',
          }
        }
      );

    } catch (err) {
      console.log("caught error", err);
    }  
  }

  const updateListing = async (updateListing: any, id: string) => {
    try {
      return await axios.post(
       `${siteUrl}/wp-json/wp/v2/properties/${id}`,
       updateListing,
       {
         headers: {
         Authorization: `Bearer ${token}`,
         }
       }
     );

   } catch (err) {
     console.log("caught error", err);
   }  
  }



  return (

      <appContext.Provider
        value={{
          token,
          user_id,
          user_name,          
          listing,
          loading,
          getToken,
          getListing,
          // getListingItem,
          removeListing,
          createListing,
          logout,
          uploadMedia,
          updateListing
        }}
      >
        {children}
      </appContext.Provider>

  )



}



