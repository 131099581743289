import React, {useContext, useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Container,
  Grid,
  Button
} from '@mui/material'
import { useSnackbar } from 'notistack';
import {ListingItem} from './ListingItem'
import {appContext} from '../context/appContext'
import {IListing} from '../types'


const Listing: React.FC = () => {


    // @ts-ignore
    const {getListing, listing, removeListing, user_id} = useContext(appContext)
    console.log(user_id)
    console.log(listing.length)
    console.log(user_id !== '1' && listing.length)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const navigate = useNavigate();

    useEffect( () => {
      getListing(user_id)
    },[])

    const removeHandler = (id: string) => {

      const removeConfirmation = () => {
        removeListing(id)
        
      }

    enqueueSnackbar('Do you whant to remove a project?', { variant: 'error' ,
      action: (
        <>
        <Button
          color="secondary"
          size="small"
          className="cta cta-small"
          onClick={() => removeConfirmation()}
        >
          Yes
        </Button>

        <Button
        color="primary"
        size="small"
        className="cta cta-small"
        onClick={() => closeSnackbar()}
      >
        No
      </Button>   
      </>     
      )
    });

    }

    const editHandler = (id: number) => {
      navigate(`/project-update/${id}`);
    }

  return (
    <Container maxWidth="lg">
            <Grid container spacing={3}>
              {listing.map((litingItem: IListing, id: number) => {
                return (
                <Grid item md={4} key={litingItem.title + listing.id + id}>
                  <ListingItem
                    listing = {litingItem}
                    removeHandler = {removeHandler}
                    editHandler = {() => { editHandler(id)}}
                  />
                </Grid>
                )
                })}
      </Grid>

      {

        user_id === '1' || listing.length === 0 ?

        <Grid container spacing={3} sx={{mt: 5, mb: 10}}>
        <Button
          color="secondary"
          size="small"
          className='cta cta-small cta-create'
          onClick={() => { navigate(`/project-create/`);}}
        >
          Create new Project
        </Button>
        </Grid>  :

      ''
      }



    </Container>
  )
}

export default Listing;