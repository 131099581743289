import React, {useContext} from 'react'
import {appContext} from '../context/appContext'
import {
  Container, 
  List,
  ListItem, 
  Typography,
  TextField,
  Button,
  // CircularProgress
} from '@mui/material'
import { Controller, useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
// import { Password } from '@mui/icons-material';

import {FormValues} from '../types'






const Login: React.FC = () => {
  // @ts-ignore
  const {getToken} = useContext(appContext)

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormValues>();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const submitHandler = async (formData: FormValues ) => {
    await getToken(formData)     
  }

  return (
    <Container maxWidth="sm">
      <form onSubmit={handleSubmit(submitHandler)} className="login-form">
    <Typography component="h1" variant="h1">
      Login
    </Typography>
    <List>
      <ListItem sx={{p: 0, mb: 2}}>
        <Controller
          name="username"
          control={control}
          defaultValue=""
          rules={{
            required: true,
            minLength: 5,
          }}
          render={({ field }) => (
            <TextField
              variant="outlined"
              fullWidth
              id="username"
              label="Name"
              inputProps={{ type: 'name' }}
              error={Boolean(errors.username)}
              sx={{input: {backgroundColor: 'white'}}}
              InputLabelProps={{
                style: { fontSize: '18px', color: 'black'},
              }}
              helperText={
                errors.username
                ? errors.username.type === 'minLength'
                  ? 'Name length is more than 5'
                  : 'Name is required'
                : ''
              }
              {...field}
            ></TextField>
          )}
        ></Controller>
      </ListItem>
      <ListItem sx={{p: 0, mb: 2}}>
        <Controller
          name="password"
          control={control}
          defaultValue=""
          rules={{
            required: true,
            minLength: 6,
          }}
          render={({ field }) => (
            <TextField
              variant="outlined"
              fullWidth
              id="password"
              label="Password"
              inputProps={{ type: 'password' }}
              sx={{input: {backgroundColor: 'white'}}}
              InputLabelProps={{
                style: { fontSize: '18px', color: 'black'},
              }}
              error={Boolean(errors.password)}
              helperText={
                errors.password
                  ? errors.password.type === 'minLength'
                    ? 'Password length is more than 5'
                    : 'Password is required'
                  : ''
              }
              {...field}
            ></TextField>
          )}
        ></Controller>
      </ListItem>
      <ListItem sx={{p: 0, mb: 2}}>
        <Button variant="contained" type="submit" fullWidth className='cta'>
          Login
        </Button>
      </ListItem>
    </List>
      </form>
    </Container>  
  )
}

export default Login;