import React from 'react';
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from '@mui/material';

import {SingleListingProps} from '../types'

export const ListingItem: React.FC<SingleListingProps> = ({listing, removeHandler, editHandler}) => {


  return (
    <Card>

        <div>
          <CardMedia
            component="img"
            image={listing.feature_image}
            title={listing.title}
            style={{height: '264px'}}
          ></CardMedia>
          <CardContent>
            <Typography variant='h3'>{listing.title}</Typography>
          </CardContent>
        </div>

      <CardActions sx={{pl: 2, pb: 1.8}}>
        <Button
          size="small"
          variant="contained" className='cta cta-small'
          onClick={() => editHandler(listing.id)}
        >
          Update
        </Button>
        <Button
          size="small"
          color="secondary"
          onClick={() => removeHandler(listing.id)}
        >
          Remove
        </Button>
      </CardActions>
    </Card>
  )
}