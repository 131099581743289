import React from 'react';
import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom';
import Home from './components/Home';
import ProjectUpdate from './components/ProjectUpdate'
import ProjectCreate from './components/ProjectCreate'
import { Layout } from './components/Layout'
import { SnackbarProvider } from 'notistack';
import {AppState} from './context/appState'
import {ThemeProvider, createTheme} from '@mui/material'



const theme = createTheme({
  // @ts-ignore
  status: {
    danger: '#e53e3e',
  },
  palette: {
    primary: {
      main: '#ffffff',
    },
    secondary: {
      main: '#0f4757',
    },
  },
  typography: {
    h1: {
      fontSize: '2.5rem',
      fontWeight: '400',
      fontFamily: 'montserrat, sans-serif',
      marginBottom: '24px'
    },
    h3: {
      fontSize: '1.4rem',
      fontWeight: '600',
      fontFamily: 'montserrat, sans-serif',
      marginBottom: '0',
      letterSpacing: '0.01em'
    },
    h5: {
      fontSize: '1rem',
      fontFamily: 'montserrat, sans-serif',
      fontWeight: '600',
      textTransform: 'uppercase'
    },
    body1: {
      fontFamily: 'dico-slab serif',
      fontSize: '1rem',
      fontWeight: '400'
    }
  },
  overrides: {
    MuiFilledInput: {
      root: {
        backgroundColor: "#ffffff",
        "&:hover": {
          backgroundColor: "rgb(250, 232, 241)",
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            backgroundColor: "rgb(232, 241, 250)"
          }
        },
        "&.Mui-focused": {
          backgroundColor: "rgb(250, 241, 232)"
        }
      }
    }
  },
  spacing: 8,

  
  
});





const App: React.FC = () => {
  return (
    <>
    {/* <CssBaseline/> */}

    <Router>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={1} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}> 
          <AppState>
            <Layout>
              <Routes>
                <Route path="/project-update/:id" element={<ProjectUpdate/>} />
                <Route path="/project-create/" element={<ProjectCreate/>} />
                <Route path="/"  element={<Home/>} />
                <Route path="*"  element={<Home/>} />
                {/* <Route path="/project-update/0" element={<Navigate replace to="/" />} /> */}
              </Routes>
            </Layout>
          </AppState>
        </SnackbarProvider>   
      </ThemeProvider>

  </Router>
  </>

  );
}

export default App;
