export const restToProps = (data) => {
  return data.map(item => {
    return ({
      id: item.id.toString(),
      date: item.date,
      slug: item.slug,
      status: item.status,
      link: item.link,
      title: item.title.rendered,
      content: item.content.rendered.replaceAll('<p class="Body-16">', '').replaceAll('</p>', '\n').replaceAll('<p>', '').replaceAll('<br>', '\n') || '',
      feature_image: typeof item.fimg_url === 'string' ? item.fimg_url.toString() : '',
      feature_image_id: item.featured_media ? item.featured_media.toString() : '',
      acf_main_image: item.acf.main_image && item.acf.main_image.sizes['property-gallery'] ? item.acf.main_image.sizes['property-gallery'] : '',
      acf_main_image_id: item.acf.main_image && item.acf.main_image.id ? item.acf.main_image.id.toString() : '',
      acf_banner_images: item.acf.banner_images && item.acf.banner_images.sizes['property-banner'] ? item.acf.banner_images.sizes['property-banner'] : '',
      acf_banner_images_id: item.acf.banner_images && item.acf.banner_images.id ? item.acf.banner_images.id.toString() : '', 
      acf_suburb: item.acf.suburb ? item.acf.suburb : '',
      acf_city: item.acf.city ? item.acf.city : '',
      acf_post_code: item.acf.post_code ? item.acf.post_code : '',
      acf_price: item.acf.price ?  item.acf.price : '',
      acf_developer: item.acf.developer ? item.acf.developer : '',
      acf_architect: item.acf.architect ? item.acf.architect : '',
      acf_expected_completion: item.acf.expected_completion ? item.acf.expected_completion : '',
      acf_bedroom: item.acf.bedroom ? item.acf.bedroom : '',
      acf_bathroom: item.acf.bathroom ? item.acf.bathroom  : '',
      acf_car: item.acf.car ? item.acf.car : '',
      acf_headline: item.acf.headline ? item.acf.headline  : '',
      acf_key_features: item.acf.key_features ? item.acf.key_features  : '',
      acf_video: item.acf.video ? item.acf.video : '',
      acf_gallery: item.acf.gallery.length > 0 ? item.acf.gallery.map((gallery_item) => {
        return ({
          original: gallery_item && gallery_item.sizes['1536x1536'] ? gallery_item.sizes['1536x1536'] : '',
          thumbnail: gallery_item && gallery_item.sizes['property-gallery'] ? gallery_item.sizes['property-gallery'] : '',
          id: gallery_item && gallery_item.id ? gallery_item.id.toString() : ''
        })
      }) : [],
      acf_agent_name: item.acf.agent_name ? item.acf.agent_name : '',
      acf_phone: item.acf.phone ? item.acf.phone : '',
      acf_email: item.acf.email ? item.acf.email : '',
      acf_website: item.acf.website ? item.acf.website : '',
      acf_project_logo: item.acf.project_logo ? item.acf.project_logo : '',
      acf_poster: item.acf.poster ? item.acf.poster : '',
      acf_podcast: item.acf.podcast ? item.acf.podcast : '',
      acf_podcast_title: item.acf.podcast_title ? item.acf.podcast_title : ''
    })
  })
}